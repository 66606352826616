<template>
  <div v-if="reqDetail !== null" class="mt-4">
    <div class="card top_primary_card bg-primary font-bold text-white p-2 rounded-xl relative w-full">
      <div class=" text-text2 heading-4 absolute left-3 mr-1 top-2 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="$router.go(-1)">
        <i class="fa-solid fa-arrow-left"></i>
      </div>
      <div class="flex justify-center items-center">
        <h1 v-if="reqDetail.requestTitle !== ''" class="heading-1 text-center">{{reqDetail.requestTitle}}</h1>
        <h1 v-else class="heading-1 text-center">Request for {{reqDetail.companyName}}</h1>
      </div>
    </div>
    <!-- <div class="mb-4 mt-2 flex flex-wrap gap-4 card rounded-xl bg-white p-4">
      <div v-if="!reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted" class="text-error"><i class="fa fa-times"></i><span class="px-2">Not Completed</span></div>
      <div v-if="reqDetail.isCompleted && !reqDetail.isArchived && !reqDetail.isConverted" class="text-primary"><i class="fa fa-times"></i><span class="px-2">Completed</span></div>
      <div v-if="!reqDetail.isCompleted && reqDetail.isArchived && !reqDetail.isConverted" class="text-primary"><i class="fa fa-times"></i><span class="px-2">Unarchive</span></div>
      <div v-if="reqDetail.isArchived && !reqDetail.isConverted" class="bg-gray4 p-2 rounded-xl text-white hover:shadow-xl cursor-pointer hover:bg-opacity-75 px-4"><span class="px-2">Unarchive</span></div>
    </div> -->
    <div>
      <div class="lg:grid grid-cols-3 gap-4 mt-4">
        <div>
          <div class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div class="relative">
              <p>Request #{{reqDetail.requestId}}</p>
              <span class=" text-primary cursor-pointer" @click.stop="customerDetail">{{reqDetail.companyName}} ({{ reqDetail.isLead ? 'Lead' : 'Customer' }})</span>
              <p class="">Created Date: {{reqDetail.addedDate| dayDateType }}</p>
            </div>
          </div>
          <div v-if="reqDetail.latitude !== 0 && reqDetail.longitude !== 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <div class="mb-4 flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(reqDetail.latitude, reqDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">
                  <span v-if="reqDetail.fullAddress !== ''">{{reqDetail.fullAddress}}</span>
                  <span v-else>
                    <span v-if="reqDetail.addressLine1 !== ''">{{reqDetail.addressLine1}}, </span> 
                    <span v-if="reqDetail.addressLine2 !== ''">{{reqDetail.addressLine2}}, </span> 
                    <span v-if="reqDetail.city !== ''">{{reqDetail.city}}, </span> 
                    <span v-if="reqDetail.state !== ''">{{reqDetail.state}}, </span> 
                    <span v-if="reqDetail.country !== ''">{{reqDetail.country}}-</span> 
                    <span v-if="reqDetail.zip !== ''">{{reqDetail.zip}}</span>
                  </span>
                  </p>
                  <p  class="text-text1 heading-6">
                    <span v-if="reqDetail.houseNo !== ''">#: {{reqDetail.houseNo}}</span>
                    <span v-if="reqDetail.floorNumber !== ''"> (Floor: {{reqDetail.floorNumber}})</span>
                  </p>
                  <p v-if="reqDetail.buildingName !== ''" class="text-text1 whitespace-pre-wrap">Bldg: {{reqDetail.buildingName}}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="reqDetail.latitude === 0 && reqDetail.longitude === 0" class="card bg-white rounded-xl p-4 mb-4">
            <div class="flex justify-between items-center mb-2">
              <p class="font-bold">Address</p>
            </div>
            <div class="">
              <div class="flex whitespace-nowrap items-start" >
                <div>
                  <span @click="openMap(orgDetail.latitude, orgDetail.longitude)" class=" cursor-pointer text-primary "><i class="fa-solid fa-location-dot w-8"></i><span class="text-gray2"></span></span>
                </div>
                <div class="pl-1">
                  <p class="pr-4 flex flex-wrap font-bold text-text2 whitespace-pre-wrap">At Office</p>
                </div>
              </div>
            </div>
          </div>
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.instructions !== ''" class="text-text1 font-bold mb-2 heading-4 ">Instructions</p>
              <p v-else class="text-text1 heading-4 ">No Instructions</p>
              <p>{{reqDetail.instructions}}</p>
            </div>
          </div>
          <div  class="text-text1  card rounded-xl bg-white mb-4 p-4">
            <div>
              <p v-if="reqDetail.serviceDetail !== ''" class="text-text1 font-bold mb-2 heading-4 ">Service Details</p>
              <p v-else class="text-text1 heading-4 ">No Service Details</p>
              <p class="whitespace-pre-line">{{reqDetail.serviceDetail}}</p>
            </div>
          </div>
          <div  class="card rounded-xl bg-white mb-4 p-4 " v-if="(reqDetail.startTime !== '' && reqDetail.endTime !== '') || reqDetail.isUnschedule">
            <div class=" relative">
              <p class="text-text1 font-bold mb-2 heading-4 ">Assessment details</p>
                <div v-if="reqDetail.isUnschedule" class="mb-2">
                  <span class="text-primary"><i class="fa-solid fa-calendar-days heading-5"></i></span> Unscheduled
                </div>
                <div v-else class="mb-2">
                  <span class="text-primary mr-2"><i class="fa-solid fa-calendar-days heading-5"></i></span> 
                  <span v-if="!allDay" class="text-gray4">{{reqDetail.startTime | logTimeShow}} - {{reqDetail.endTime | leaveTimeFilterForActivity}}</span>
                  <span v-else class="text-gray4">{{reqDetail.startTime | dateFilterInvoiceRegularFormate}}: Full-Day</span>
                </div>
                <div v-if="reqDetail.assignToList.length > 0">
                  <span class="text-gray3">Assign to: </span>
                  <span v-for="(user, index) in reqDetail.assignToList" :key="index">
                    <span>{{user.firstName}} {{user.lastName}}</span>
                    <span v-if="index < reqDetail.assignToList.length -1">, </span>
                  </span>
                </div>
            </div>
          </div>
        </div>
        <div class="col-span-2">
          <div class="card rounded-xl bg-white mb-4 p-4" v-if="!reqDetail.isUnschedule">
            <p class="text-text1 font-bold mb-2 heading-4 ">Your Availability</p>
            <div class="flex items-center">
              <div class="text-gray4 w-56">Availability date:</div>
              <div v-if="reqDetail.availabilityDate !== ''" >{{reqDetail.availabilityDate | dayDateType}}</div>
              <div v-else>--</div>
            </div>
            <div class="flex items-center">
              <div class="text-gray4 w-56">Alternate availability date:</div> 
              <div v-if="reqDetail.otherAvailDate !== ''" >{{reqDetail.otherAvailDate | dayDateType}}</div>
              <div v-else>--</div>
            </div>
            <p class="mt-2"><span class=" font-bold">Preferred arrival times</span></p>
            <div class="flex items-center">
              <div class="w-80">Any Time</div>
              <span class=" text-primary heading-4" v-if="anyTime"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Morning</div>
              <span class=" text-primary heading-4" v-if="morning"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Afternoon</div>
              <span class=" text-primary heading-4" v-if="afternoon"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
            <div class="flex items-center">
              <div class="w-80">Evening</div>
              <span class=" text-primary heading-4" v-if="evening"><i class="fa-solid fa-check"></i></span>
              <span v-else>--</span>
            </div>
          </div>
          <div  class="card rounded-xl bg-white mb-4 p-4 " v-else>
            <div class=" relative" @click="addUpdateshedule = !addUpdateshedule" >
              <div class="text-primary cursor-pointer">
                <span class="text-primary pr-2"><i class="fa-solid fa-calendar-days heading-5"></i></span> Add Schedule
              </div>
            </div>
          </div>
            <div v-if="reqDetail !== null" class="card rounded-xl bg-white mb-4 p-4">
              <NotesList :detailId="parseInt(this.$route.params.requestId)" :requestType="'request'" :noteTitle="'Request Note'" />
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
var moment = require('moment')
import ADMINAPI from '@/View/Admin/api/Admin.js'
import NotesList from '@/View/Admin/customer/jobs/NotesList.vue';
export default {
  name: "lead",
  components: {
    NotesList,
  },
  data() {
    return {
      orgDetail: null,
      showMenuList: false,
      overView: null,
      reqDetail: null,
      anyTime: false,
      addUpdateshedule: false,
      morning: false,
      afternoon: false,
      evening: false,
      deleteReqConfirm: false,
      allDay: false,
      selectedTeam: [],
       assementObj: {
        instructions : '',
        isUnschedule : true,
        isAnyTime : false,
        startDateTime: '',
        endDateTime: '',
        isSchedule: false,
      },
    };
  },
  created() {},
  mounted() {
    document.title = 'Request Detail'
    setTimeout(() => {
       this.geReqDetail()
    }, 200);
     this.$root.$on('confirmBoxHandler', (data) => {
      if (data && this.deleteReqConfirm) {
        this.deleteReq()
      }
      document.body.style = 'overflow: visible;'
      this.deleteReqConfirm = false
    })
     this.$root.$on('closeScheduleHandler', (response, data) => {
      console.log(response);
      console.log(data);
      if (response) {
        this.geReqDetail()
      }
        this.addUpdateshedule = false
    })
  },
  beforeDestroy() {
    this.$root.$off("confirmBoxHandler");
  },
  methods: {
    closeList () {
      this.showMenuList = false
    },
    customerDetail () {
      if (this.reqDetail.isLead) {
        this.$router.push({name: 'LeadsDetail', params: {customerId: this.reqDetail.customerId}})
      } else {
        this.$router.push({name: 'CustomersDetail', params: {customerId: this.reqDetail.customerId}})
      }
    },
    AddUpdateSheduleBtn () {
      console.log('click')
      this.addUpdateshedule = true
    },
    editRequest () {
      this.$router.push({name: 'editRequest', params: {requestId: parseInt(this.$route.params.requestId)}})
    },
    geReqDetail() {
      this.$store.dispatch("SetLoader", {status: true,message: "Loading...",});
      ADMINAPI.GetRequestDetail(
        parseInt(this.$route.params.orgId),
        parseInt(this.$route.params.requestId),
        (response) => {
          this.reqDetail = response.Data;
          let temp = response.Data.arrivalTime.split(",")
          this.selectedTeam = response.Data.assignToList
          this.assementObj.instructions = response.Data.instructions
          this.assementObj.isUnschedule = response.Data.isUnschedule
          
          this.assementObj.startDateTime = response.Data.startTime
          this.assementObj.endDateTime = response.Data.endTime
          this.assementObj.isSchedule = true
          temp.map(text => {
            if (text === 'morning') {
              this.morning = true
            }
            if (text === 'anyTime') {
              this.anyTime = true
            }
            if (text === 'afternoon') {
              this.afternoon = true
            }
            if (text === 'evening') {
              this.evening = true
            }
          })
          if (this.reqDetail.latitude === 0 && this.reqDetail.longitude === 0) {
            this.$store.dispatch("SetLoader", { status: true, message: "Loading...",});
            ADMINAPI.getOrgDetail(
              parseInt(this.$route.params.orgId),
              (response) => {
                this.orgDetail = response.Data
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              },
              (err) => {
                this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
                this.$store.dispatch("SetLoader", { status: false, message: "" });
              }
            );
          }
         if (response.Data.startTime !== "" && response.Data.endTime) {
          var startTime = moment(response.Data.startTime,)
          var endTime = moment(response.Data.endTime,)
          var hoursDiff = endTime.diff(startTime, 'hours')
          console.log("hoursDiff", hoursDiff);
          var minutesDiff = endTime.diff(startTime, 'minutes') % 60
          console.log("minutesDiff", minutesDiff);
          if ((hoursDiff === 23 && minutesDiff === 59) || (hoursDiff === 24 && minutesDiff === 0)) {
            this.allDay = true
            this.assementObj.isAnyTime = true
          } else {
            this.allDay = false
          }
         }
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        },
        (err) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: err.message, color: 'error'})
          this.$store.dispatch("SetLoader", { status: false, message: "" });
        }
      );
    },
    openMap (lat, lng) {
     let url = 'https://www.google.com/maps/search/?api=1&query=' + lat + ',' + lng
      window.open(url, '_blank')
    },
  },
};
</script>
<style scoped>
</style>